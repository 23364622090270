  .settingstab
{
 display: none;
}
.settingstab-mobile{
  display: inline-flex;
  /*justify-content: flex-end;*/
  margin-top: 10px;  
 padding-left: 26px !important;
}
.mobile-icons
{
	display: flex;
	flex-direction: column;
	
}
.settingstab-mobile .vertIcon 
{
     margin-right: 10px !important;
    padding-right: 25px !important;
    right: 3px; 
}
.settingstab-mobile .mobile-icons
{
margin-top: 30px;
}
.vertIcon {
	position: absolute;
	display: inline-flex;
	justify-content: flex-end;
}
input.headersearch
  {
background-color: rgba(255,255,255,0.12);
    -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 25px;
    padding: 7px 16px;
    color: #fff;
    border: none;
    width: 220px;
  }

.searchwidth
{
	width: 82% ! important;
} 
.scrollTable
{
  width:1500px !important;
  overflow: auto !important;
}
.autoScroll
{
      overflow: auto !important;
      max-width: 1500px !important;
}
.menuPadding
{
  padding-top: 6px !important;
}
.mobile-label {
    width: 185px;
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    text-align: start ! important;
  }

 .tableAlignCenter .mat-header-row {
    display: none !important;
  }

 .tableAlignCenter .mat-row { 
    flex-direction: column;
    align-items: start ! important;
    padding: 8px 24px;

  }
.tableAlignCenter .noLeftPadding{
  padding-left: 0px ! important;
}
 .tableAlignCenter{
      align-items: center;


}
.mobileHelp{
  padding-left: 0px;
  right: 23px ! important;
  margin-top:0px; 
  top: 12px ! important;
}
.mobileTitlePadding{
  margin-top: 20px ! important;

}
.autoTableScroll
{
  overflow:auto !important;
}
.autoContentScroll
{
  overflow:auto !important;
  width:1000px !important;
}
.divScroll
{
  overflow:auto !important;
  width:600px !important;
}
.divtableScroll
{
  overflow:auto !important;
  width:1500px !important;
}
.fontSize13Px
{
  font-size: 13px !important;

}
.titlePadding{
   margin-top: 10px ! important;
}
.padding0Px{
  padding:0px ! important;
}
.fullWidth{
  width:100% ! important;
}
.marginLeft15Px{
  margin-left: 15px ! important;
}
.paddingBottom{
  padding-bottom: 40px ! important;
}
.margin17px{
margin-top: 1% !important;
margin-bottom: 7% !important;
}
.example-full-width {
  width: 30% ! important; 
}
.leftPadding0Px{
  padding-left: 0px ! important;
}
.imageSiza
{
  max-width: 195px !important;
  max-height: 240px !important; 
  border: 1px solid !important;
  display: block !important;
  margin-right:auto !important;
  margin-left:auto !important;
}
.marginAuto
{
   padding-left:25% !important;
}
mat-dialog-container#feedbackpopup {
    width: 100% !important;
    height: 380px !important;
}
mat-dialog-container#changepasspopup {
    width: 100% !important;
    height: 300px !important;
}
mat-dialog-container#createtaskcomp
{
  width: 100% !important;
}
mat-dialog-container#changepasspopup .mat-raised-button
{
 min-width: 45% !important; 
}
.apidocument_resp
{
  width:100% !important;
}
table.available_api_ser
{
  width:100% !important;
}
.dec_vi_ui .mat-form-field, .createcontact .mat-form-field
{
  width:100%;
}
.createcontact mat-expansion-panel-header
{
  width:100%;
}
.boxminheight {
    height: 100% !important;
    padding: 10px 0px;
}
.displaytable
{
  display: flex;
}
.tabledivresponsive {
    width: 100% !important;
    overflow: auto !important;
}