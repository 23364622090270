  .settingstab
{
 
 display: inline-flex;
 justify-content: flex-end;
 padding-top: 12px;
}
.settingstab-mobile{
  display: none;
}
.scrollTable
{
  width:1500px !important;
  overflow: auto !important;
}
.mobile-label
{
	display:none;
}
.paddingTop10px
{
  padding-top: 10px !important;

}
.mobileTitlePadding{
  margin-top: 20px ! important;

}
.tableCardScroll
{
	overflow:auto !important;
}
.tableContentScroll
{
	overflow:auto !important;
	width:1000px !important;
}
.divScroll
{
  overflow:auto !important;
  width:600px !important;
}
.autoTableScroll
{
  overflow:auto !important;
}
.autoContentScroll
{
  overflow:auto !important;
  width:1000px !important;
}
.clearBoth
{
	clear:both;
}
.fontSize13Px
{
  font-size: 13px !important;

}
.cardheadertitle{
  padding-top: 15px ! important;
}
.paddingBottom{
  padding-bottom: 40px ! important;
}
.imageSiza
{
  max-width: 190px !important;
  max-height: 240px !important; 
  border: 1px solid !important;
  display: block !important;
}
.mat-expansion-panel-header-description, .mat-expansion-panel-header-title
{
  margin-right: 5px !important;
}
mat-dialog-container#feedbackpopup {
    width: 100% !important;
    height: 380px !important;
}
mat-dialog-container#changepasspopup {
    width: 100% !important;
    height: 380px !important;
}
.displaytable
{
  display: flex;
}
.divtableScroll
{
  overflow:auto !important;
  width:1500px !important;
}
.tabledivresponsive {
    width: 100% !important;
    overflow: auto !important;
}