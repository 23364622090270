/**
css for LAPTOP UPTP 1500 PX
**/
  /** settings menu for laptop **/
  .settingstab
{
  /* margin-top:12px; */
  padding: 8px 0px;
}
.settingstab-mobile{
  display: none;
}


.mat-table {
  overflow: auto;
 /*  max-height: 500px; */
}

.mat-row{
  /* min-height: 38px !important; */
  display: flex;
    line-height: 16px !important;
  font-size: 12px !important;
}

mat-sidenav a.mat-menu-item
{
padding-left: 5px;
    font-size: 14px;  
}
 
.viewcontacts
{
  min-height: 613px;
}

.searchResults{
  min-height: 447px;
} 

label.submenu
{
  padding: 0px !important;
  width: 100%;
  background-color: 
}
mat-header-cell{
  font-weight: bold !important;
}
 