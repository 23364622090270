@import url("./style-l-1500.css") only screen and (max-width: 1500px);
@import url("./style-g-1500.css") only screen and (min-width: 1501px);


@import url("./styles/style-less-480px.css") only screen and (min-width: 320px) and (max-width: 479px);
@import url("./styles/style-480-767px.css") only screen and (min-width: 480px) and (max-width: 767px);
@import url("./styles/style-768-1024px.css") only screen and (min-width: 768px) and (max-width: 1024px);
@import url("./styles/style-1024-1199px.css") only screen and (min-width: 1025px) and (max-width: 1199px);
@import url("./styles/style-greater-1200px.css") only screen and (min-width: 1200px);


/* You can add global styles to this file, and also import other style files */
/* Application-wide Styles */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
 
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

 <!-- Latest compiled and minified CSS -->
:focus {
    outline: none !important;
}
.col-lg-p-10{
  width:100% 
}

.col-lg-p-1-5{
  width:5% 
}

.col-lg-p-1{
  width:10% 
}
.col-lg-14{
  width: 14% 
}
.col-lg-p-2{
  width:20% 
}

.col-lg-p-3{
  width:30% 
}

.col-lg-p-4{
  width:40% 
}

.col-lg-p-5{
  width:50% 
}

.col-lg-p-6{
  width:60% 
}

.col-lg-p-7{
  width:70% 
}

.col-lg-p-8{
  width:80% 
}

.col-lg-p-9{
  width:90% 
}
mat-accordion{
  font-size: 20px;
}

body
 {
font-family: 'Open Sans';
  margin: 0;
}
body, input[text] {
  color: #888;
  /* font-family: Cambria, Georgia; */
}
/* everywhere else */
* {
 font-family: 'Lato', sans-serif;
}

.reportLeftAlign{
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
  
}

.reportRightAlign{
  display: flex;
  justify-content: flex-end;
   padding-right: 10px;
  
}
.reportCenterAlign{
  display: flex;
  justify-content: center;
   padding-right: 10px;
   
}
.reportValueRightAlign{
  /*display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  */
}

.reportHeaderRightAlign{
  /*display: flex;
  justify-content: center;
   padding-right: 10px;
   */
}

.reportHeaderLeftAlign{
  /*display: flex;
  justify-content: left;
  padding-left: 5px;
  */
}

.textarea-width{
  margin-top: 0px;
  margin-bottom: 0px;
  height: 45px;
}

.mat-table {
  overflow: auto;
 /*  max-height: 1000px; */
}
.mat-column-select {
  overflow: initial;
}
.labelcell{
  margin-top: 3%;
}

.mattableRightAlign{
  justify-content: flex-end;
}
.setting-icons a {
    display: contents;
}

.nopadding
{
  padding:0px !important;
  margin:0px !important;

}
 .paddingVendorSearch{
    padding:0px 0px 0px 15px !important;
  margin:0px !important;
 }
 .link_style
 {
  color:#3399ff;
  padding-left: 15px;
  cursor: pointer; 
  font-size: 10px;
} 
.font_bold
{
font-size: 12px;
}

    mat-form-field{
      line-height: 1;
    }  

    
    
/* All Review Screens CSS - PLS DO NOT DELETE */

.example-container {
  margin-left: 54px;
  display: flex;
  flex-direction: column;
 /*  max-height: 500px; */
  min-width: 300px;
 /*  margin-top: 4px;
 margin-top: 40px; */
}
 
 .margin-left{
  margin-left: 1px !important;
 } 

.mat-column-select {
  overflow: visible;
}

.boldLabel{
  font-weight: 800;
}
.boldHeader{
  font-weight: 800 !important;
  color: black !important;
}
.headerSpace{
padding-left: 2%;
}
.example-full-width {
  width: 15%;
  font-size: 15px;
}

.inputWidth{
  width: 93%;
}
.module-center-align{   
    display: flex;
  justify-content: center;
  
}
.alignRight{
  text-align: right !important;
  /*display: flex;
  justify-content: flex-end;*/
}
.amountGridAlignRight{
  text-align: right !important;
  justify-content: center;
}
.alignCenter{
  text-align: center !important;
  /*justify-content: center;*/
}
.alignLeft{
  text-align: left !important;
}
.paddingLeftRight
{
  padding-left: 15px; 
  padding-right: 10px;
}
.amountAlignRight{
  text-align: right !important;
}
.rightCorner{
  text-align: right;
  float: right !important;
  padding: 12px 0px;
}

.alignJustifyCenter{
   justify-content: center;
}

/*Below style is used in directive for dropdown */
.dropdown-item{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 7rem;
  max-width: 17.5rem;
  min-height: 2.5rem;
  padding: .8rem 1rem;
  overflow: hidden;
  line-height: 1;
  text-overflow: ellipsis;
  word-wrap: break-word;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  clear: both;
  font-weight: 400;
  color: rgba(0,0,0,.87);
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  width: 100%;
}

.dropdown-divider{
  height: 1px;
  margin: .25rem 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.fltheader{
  color: #999;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 15px 3px 10px;
  font-weight: bolder;
}

.pull-left{
  right: 0 !important;
  left: auto !important;
  float: left !important;
}

.pull-left-one{
  right: 0 !important;
  left: auto !important;
  float: right !important;
}

.hrefStyle{
  color: white;
}
 
.pClass{
  margin-top: -2%;
}

/* Review Screens CSS - END */

mat-sidenav{
  width: 170px;
}
button{
  color:#3f51b5;
}
/* Menu Css */
.mat-expansion-panel-spacing
{
  margin: 0px !important;
}
.mat-expansion-panel-header
{
  padding: 0px 10px !important;

}
.mat-sidenav, .mat-expansion-panel-custom
{
  background-color: #ededed;

}
.mat-expansion-panel-custom{
    height: 40px !important;
}
.mat-accordion
{
  box-shadow: none;
}
label.submenu a
{
  
  text-decoration: none;
  font-size: 0.85 !important;
  outline:0px !important;
  display: flex;
}
label.submenu a .mat-icon
{
      font-size: 18px !important;
}
.material-icons
{
  color:rgba(76, 75, 75, 1);
  padding-right: 8px;
  font-size: 20px;

}

.matdatepickericon{
  font-size: 24px;
}
/*.buttons{
  padding-left:38%;
}*/
.tableheader{
      font-weight: bold;
         
          font-size: 14px;
          text-align: center;
              margin-top: 0%;
         
}

.subtable{
  margin-right: 6%;
}
.restable{
  margin-right: 6.5%;
}
.leftalign
{
  text-align: left !important;
}
.rightalign
{
  text-align: right !important;
}
.footer{
 padding-top: 2%;
 background-color: #eaeaea73;
 margin-top: 2%;
}

.imageicon{
  font-size: 55px;
}

.cost{
  text-align: right; 
}

.text{
  text-align: left;
 }

.qty{
  text-align: center;
}
.textflex{
  flex: 0 1 300px !important;
}
.textitemdet{
  flex: 0 1 45% !important;
}
.qtyflex{
flex: 0 1 150px !important;
}

mat-card-title{
font-size: 15px;
margin-bottom: 0px !important;
/*padding-left: 24px;*/
    padding-left: 15px;
    padding-right: 15px;
    line-height: 50px;
}
.mat-card-title .mat-icon{    vertical-align: middle;color: #3b78e7;
    line-height: 20px;margin-right: 5px;}
.mat-divider{border-top-color: rgba(0, 0, 0, 0.07) !important;}
mat-card{
  padding-bottom: 7px !important;
}
 
 .mat-tab-label{
  font-weight: bold;
 }
 .mat-tab-header{
  /*background: #d3d3d3;*/
      background: #9898982e;
          border-bottom: 1px solid rgb(228, 228, 228);
 }
 {    text-align: center !important;}
 .line_height_tr tr{ line-height: 50px !important; }
 .line_height_tr th {text-align: center !important;}
 .table-active, .table-active>td, .table-active>th {    background-color: #ececec !important;}
 .font-weight-bold{
  font-weight: bold;
 }
 .priceline_height_tr tr{ line-height: 40px !important; }
 .b_d_address .font_bold{    font-size: 14px;}
 .b_d_address .link_style{    float: right;
    background: #c39d2d;
    padding: 1px 5px;
    color: #fff;
    border-radius: 3px;
    font-size: 9px;
    margin-right: 15px;
    margin-bottom: 0px;cursor: pointer;}

.mat-expansion-panel-header/* .mat-expansion-panel-header{ */
  {
background-color:rgb(237, 237, 237);
}
 
 .matIconColor{
    color: black;
 }

 .buttonWidth{
    min-width: 40px !important;
 }
.marginTop1{
  margin-top: 1% !important;
}
.notesBorder{
  border-top: 1px solid #ececec;
  border-bottom : 1px solid #ececec;  
  padding: 15px;
}
.div_ali_subtotal{line-height: 40px;
    border-bottom: 1px solid #dee2e6;
    padding: 0px !important;}
.top_align_div {    margin-top: 12px;
    margin-bottom: -8px;}
    .select_top .mat-form-field-wrapper .mat-form-field-flex{     padding-bottom: 10px !important; }
 /* Start of Header Style */
 .topheader
 {
   /* background-color: #efefef;*/ 
   background-color:#ffffff ;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
    /* padding: 0px 16px 0px 0px; */
    position: fixed !important;
    width: 100%;
     top: 0px; 
} 
.footerheader
 {
  text-align: left;
    background-color: #ededed;
  /*  position: fixed !important;  */  
    bottom: 0; 
    width:100% !important;
    left:0;
    z-index: 99;
} 

.backgroundGray{
  background-color: #fbfafa;
}

.togglemenu
{
   padding: 8px 0px;
}
.togglemenu .mat-icon
{
cursor: pointer;
color: #777e86;
font-weight: 400;
    font-size: 24px !important;
}
.settingstab
{
  margin-top:4px !important;
}
.settingstab .mat-icon {
 
  margin: 0px 12px;
 /* color: #777e86;*/
 color:#17578a;
  font-weight: 400;
    font-size: 21px !important;
}
 /* End of Header Style */
.vendorsearch, .vendoradd
{
background-color: #ececec;
padding: 3px;
border-radius: 5px;
margin-top: 1%;
height: 25px;
width: 25px;
}
.maticon{
color:black;
margin-top: 1%;
height: 25px;
width: 25px;
}
 .mat-header-row{
  background-color: #ededed;
  min-height: 40px !important;
  overflow: hidden; /*added to avoid the header scroll in mat table*/
 }

 .smallDiv{
  flex: 0 1 64px !important;
 }

 .miniDiv{
  flex: 0.35 !important;
 }

.mediumFlexDiv{
  flex: 0 1 45px !important;
 }

.mediumFlexDiv1{
  flex: 0.07 1 45px !important;
}
 
 .flex76{
  flex: 0 1 76px !important;
 }

.flex100{
  flex:0 1 100px !important;
}
.div20px{
  width: 20px !important;
}
.flexdate{
  flex:0 1 146px !important;
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center
}
.upload-drop-zone {
  height: 200px;
  border-width: 2px;
  margin-bottom: 20px;
}
.button-Algin{
    float:none;
}
.note_text{
  color: red;
}
.Amtcode-Width{
  width:65px;
}
.upload-hint{
  font-size: 12px;
}
.attach-icon{
  font-size: 16px;
}

.md-button{
  background: #a7a6a6;
  padding: 10px;
border-radius: 3px;
}
#input-file-id{
  display: none;
}
.Register-AFN{
  width:60px;
}
.header-label{
  display: inline-block;
  padding: 15px 0px 15px 0px;
  font-size: 20px;
}
.button-data{
  display: inline-flex;
  padding-top: 20px;

}
.button-row {
  padding: 0px 15px 15px 0px;
}

.textformat{
  font-family: 'Open Sans';
}

.textalgincenter{
  font-family: 'Open Sans';
  font-size: 11px;
}
.buttonalgincenter{
 align-items: center;
}
.Formdiv{
   height: 45px;
    }

    .Heder-value {
  height: 45px;
  text-align: center;
  background:#AAAAAA;
}

.version-info {
  font-size: 8pt;
  float: right;
}
.basic-container {
  padding: 5px;
}

 .content{
    margin-top: 6%;
 }
 
.tabbgcolor{
background-color: #eaeaea73;
}

.zeropadding{
padding: 0px;
}

.paddingLabels{
  padding-left: 15px;
}

.height100{
  height: 100% !important;
}
.heightSetting100{
  height:650px;
}
.height95{
  height: 95% !important;
}
.height99{
  height: 99% !important;
}
.displayBlock{
  display: block !important;
}
.width100{
  width: 100% !important;
}



@media (max-width: 1368px){

  .content {
    margin-top: 3%;
  }
  
  .settingstab .mat-icon {
    margin: 0px 4px;
  }
  
  .height60{
    height: 45% !important
  }

  .fr-wrapper{
    height:63% !important;
    overflow-x: auto;
  }
.popupOverfLow{
  overflow-y: auto;
  max-height:450px;
}
.fileAttachFontSize{
  font-size: 15px !important;
}
.paddingRight203{
  padding-right:122px!important;
}
}
@media (min-width: 1369px) {
  .content {
    margin-top: 2%;
  }
  .height60{
    height: 60% !important
  }
  .fr-wrapper{
    height:80% !important;
    overflow-x: auto;
  }
  .popupOverfLow{
  overflow-y: auto;
  max-height: 600px;
}
.fileAttachFontSize{
  font-size: 14px !important;
}
.paddingRight203{
  padding-right: 203px!important;
}
}

.footerProduction
{
    position: fixed !important;  
    bottom: 0; 
    z-index: 99; 
    height: 8%;
    width: 851px !important;
    padding-top: 1%;
    padding-bottom: 1%;
    left:335px;
    background-color: #ededed;
}

.radio_padding {
  margin-left:2% !important;
   margin-top:0.5%;
}
.pack_radio_padding{
     padding-right: 20px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
}
.matfield{
      background-color: white;

}

.fixedaction
{
  position: fixed !important;
  bottom: 0;
  padding: 15px;
  margin: 0 0 0 -20px;
  box-shadow: 0 -4px 5px -3px rgba(0,0,0,.1);
  background: #fff;
  width: 100%;
  z-index: 3;
  border-top: 1px solid #EEE;
}
.mat-option{
  font-size: 14px;
  height: 26px;
}

.table_padding
{
margin-top: 5%;
}


.checkboxAds{
    margin-top: 1%;
    padding-left: 2%;
}
.font{
  font-size: 12px;
    color: gray;
    padding-top: 5%;
    margin-left: 12px;
}

.dispClass{
  display: none !important;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.table-label-right-align{
  text-align: right !important;
}
.table-label-left-align{
  text-align: left;
}
.table-label-center-align{
  text-align: center;
}

/* Div Table */
.divTable{
	display: table;
	width: 100%;
	margin-bottom:10px;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {	
	display: table-header-group;
	background: rgba(0,0,0,.03);
	padding:10px 0px;
	
}
.divTableCell, .divTableHead {	
	display: table-cell;
    padding: 9px;
}
.divTableHeading {	
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}
/* End of Div Table */

/* Create Invoice Page Css */

.totalcal .mat-form-field-infix
{
   border-top: 0px;
   padding-top:0px;
}

.totalcal .mat-form-field-wrapper
{
 padding-bottom: 1em;
}
.calclabel
{
	font-weight:bold;
	text-align:left;
}
.paddingtop25
{
	padding-top:15px;
}

.padding25
{
  padding:15px;
}
.greyborder
{
	border-bottom: 1px solid #e4e4e4;    
}
.greyborderpad
{
	border-bottom: 1px solid #e4e4e4;
    padding-bottom: 5px;
}
.invoicefile
{
padding:10px;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 -1px 1px -1px rgba(0,0,0,.12);
margin-bottom:8px;
}
.invoicebottom
{
margin:10px;
margin-bottom:30px;
padding: 16px;
/*border: 1px solid #ededed;*/
/*background-color: #FBFAFA;*/
}
.notes-sec
{
border-left: 1px solid #ededed;	
}
.sortArrow{
font-size: 20px;
color: black;
cursor: pointer;
}
.display_flex_custm{
  display: flex;
  padding-bottom: 10px;
}
.address_div{width:75%;    line-height: 22px;}

/* End of Create Invoice Page Css */
/*Strat Report Summay Icons CSS*/
.repoticons
{
display: flex;
 -webkit-box-shadow: 0 8px 6px -6px #edf0f4;
       -moz-box-shadow: 0 8px 6px -6px #edf0f4;
            box-shadow: 0 8px 6px -6px #edf0f4;
            padding-top: 10px;
            
}
.pagealgin{
  text-align: center;
   padding-bottom: 10px;
}
.pagealgin-head{
  text-align: center;
  font-size: 16px;
  }
/*end Report Summay Icons CSS*/
.createcontact .mat-expansion-panel-body {
  padding: 25px 20px;
 
}

.createcontact .mat-radio-outer-circle {
  width: 15px;
  height: 15px;
  margin-top: 2px;
}
.createcontact .mat-radio-inner-circle {
  width: 15px;
  height: 15px;
  margin-top: 2px;
}
.createcontact .mat-radio-label-content
{
  font-weight:normal;
}
.createcontact .mat-expansion-panel-header:focus, .createcontact .mat-expansion-panel-header:hover {
  background-color: #ededed !important;
}
.createcontact .mat-expansion-panel-header-title{
  font-weight:600;
}
.createcontact .mat-raised-button {
  margin: 5px;
}

.viewcontacts
{
  min-height: 420px;
 
}

.viewcontacts .mat-header-cell {
  color: rgb(0, 0, 0);
}
.viewcontacts .mat-table {
 /* padding: 0px 10px;*/
}

.viewcontacts .mat-checkbox-inner-container
{
  width:12px;height:12px;
}
mat-sidenav .mat-expansion-panel-header-title
{
  width:100%;
}

mat-sidenav a.mat-menu-item
{
  /* width:100%; */
  display:block;
  padding: 2px !important;
}

mat-sidenav .mat-icon
{ 
 width:18px;
  height:18px;
  margin-right:10px;
  font-size:18px;
  color: #3f51b5;
}
mat-sidenav .mat-menu-item:hover
{
background:rgba(0, 0, 0, 0) !important;	
}

mat-sidenav a.mat-menu-item:hover
{
transform: translate3d(0.5em,0,0);
    transition: transform 0.5s, color 0.5s;
    transition-timing-function: cubic-bezier(0.2,1,0.3,1);
}
mat-sidenav a.mat-menu-item:focus
{
border: 0px !important;
outline: 0px !important;
}
mat-icon.mat-icon.material-icons:focus, mat-icon.mat-icon.material-icons:hover, mat-icon.mat-icon.material-icons:active {
outline: 0px !important;
}
.mat-header-row{
  position: sticky;
  top: 0;
   height:40px;
}

/*9059 */

 
.mat-table {
  display: block;
}


.mat-row, .mat-header-row {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center; 
    line-height: 16px !important;
  font-size: 12px !important;  
}

.mat-cell, .mat-header-cell {
  flex: 1; 
  overflow: hidden;
  word-wrap: break-word;
  padding: 5px;
}

.mat-cellItemGrid{
  flex: none;
}

.sort-caret {
    margin-right: 15px;
    cursor: pointer;
}

.sort {
    top: 50%;
    margin-top: 3px !important;
    position: absolute;
    margin-left: 5px;
    margin-top: -5px;
}

.sort .up {
    margin-bottom: -2px !important;
    border-bottom-color: #333 !important;
    opacity: 1;
    margin-top: -1px !important;
}

.sort .up {
    border-bottom: 4px solid #000;
    border-top: 0;
    display: block;
    margin-bottom: -5px;
}

.sort .down {
    border-top-color: #333;
    opacity: 1;
}

.paymentMadeSummaryDisplay{
    background: #f7f7f7 !important;
    padding: 10px;
    border: 1px dashed;
    border-radius: 10px;
    margin-top: 4%;
}

.GridInput{
  border: 1px solid #dee2e6 !important;
    text-align: right !important;
    padding: 2px 5px !important;
    width: 115px !important;
}

#payInFull:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.checkbox_padding
{
  padding-top: 1.5%;
}

.divcheckbox
{
  padding-left: 6%;
}

.example-h2 {
  margin: 10px;
}

.example-section {
  display: flex;
  align-content: center;
  align-items: center;
  height: 60px;
}

.example-margin {
  margin: 10px;
}

.span_chkBx_lbl{
  font-weight: 500;
  font-size: 11px;
}
.span_chkBx_lbl15{
  font-weight: 500;
  font-size: 14px;
}

.span_red_color{
  color: red;
  padding: 5px;
}
.mat_icon_color:hover {
  cursor: pointer;
  color:#3f51b5;
}
.mat_icon_color_red:hover {
  cursor: pointer;
  color:red;
}

.display_flex{
  display: flex;
}

.menu_add_btn{
  float: right; 
  width: auto;
}

.mat_icon_large{
  font-size: 60px;
  color: #3f51b5;
}

.chkBx_class{
      top: 8px;
    position: relative;
}
.fieldset_css{
  border: 1px solid #9d9d9d;
}
.legendCss{
  font-size: 15px;
}
.mat-card-view-edit-top{
  padding-top: 0px !important;
  padding-bottom: 0px!important;
}
.align_center
{
  text-align: center;
}

.scroll{
    overflow-y: scroll;
    height: 600px;
}

.scroll_Address{
    overflow-y: scroll;
    height: 360px;
}
.scroll_Category{
    overflow-y: auto;
    height: 150px;
    margin-left: 0.8%;
}

.scroll_MatTable{
    overflow-y: auto;
    max-height: 90%;
}

.scroll_Search{
    overflow-y: scroll;
     height: 400px;
}

 
/*below css is for making the backdrop light color*/
.mat-drawer-backdrop.mat-drawer-shown{
background-color: rgba(0, 0, 0, 0.2) !important;
}
 

.marginTopM2{
  margin-top: -2%;
}

.nopaddingLR
{
  padding-left: 0px !important;
  padding-right:  0px !important;

}
#insertVideo-1{
  display: none;
}
#emoticons-1{
  display: none;
}
.cardheadertitle
{
  padding-top: 5px;
}
.cardheadertitle .mat-icon
{
  margin-top: 5px;
      font-size: 24px;  
}
.cardheadertitle span
{
  padding-bottom: 5px;
  font-size: 22px;
  padding-left: 5px;
}
.sidenavigation
{
     position: fixed !important;
    overflow: hidden;

  }
.leftexpansionpanel .mat-expansion-panel-body
{
  padding: 15px 0px 0px 0px !important;
}
.leftexpansionpanel .mat-expansion-panel-header
{
  background-color: #fff;
  font-size: 0.8em;
}
.logoheader
{
  padding:12px 0px ;
  background-color: white;
}
.logotitle
{
  color:#3f51b5;
  font-size: 38px;
  margin-top: 10px;
  margin-bottom: 12px;
}
.mat-raised-button.mat-primary
{
  background-color: #0161fd;
  color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  margin-right: 8px;

}
/*.mat-raised-button
{
  padding: 5px 3px !important;
  line-height: 0px !important; 
    height: 20px;
}*/

.searchdrop, .searchmod {
    display: block;
    padding-top: 8px;
 
  }
  input.headersearch
  {
background-color: rgba(255,255,255,0.12);
    -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 25px;
    padding: 7px 16px;
    color: black;
    border: none;
    height: 30px;
  }
  input.headersearch:focus
  {
    border: none;
   background-color: #fff;
   outline: none;
   color: black;
  }
  div#mobile-menu-toggler {
    z-index: 9999;
}
  ::-webkit-input-placeholder
  {
    color: #777e86;
    font-weight: 600;
  }
  ::-moz-placeholder { /* Firefox 19+ */
  color: #777e86;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #777e86;
}
:-moz-placeholder { /* Firefox 18- */
  color: #777e86;
}
.mat-card-view-edit-top
{
  margin-bottom: 5px;
}
.mat-header-row{
min-height: 40px;
}
.right-menu-style{
   margin-top: 42px !important;
}
.cardheadertitle span{
  font-size: 19px;
}
.cardheadertitle .mat-icon{
  font-size: 20px;
}
/* height of the filter dropdown*/
.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option{
  height: 2em !important;
} 
.mat-option{
  height: 2em !important;
}
[disabled].mat-raised-button
{
  background-color:#ededed !important ;
  color: black !important;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !important;
}
.paddingbut
{
  padding: 8px 0px;
}
.loginbox
{
  padding: 0px 0px 0px 0px;
  min-height: 70vh !important;
}
.padding2P{
  padding: 2% !important;
}
.tabheader{
      margin-bottom: 1%;
    font-weight: bold;
    margin-top: 1%;
}
.mat-card-dashboard{
  margin:2px !important;
}
.chartjs-render-monitor
{
  width: 98% !important;
}
.reportLink{
  cursor:pointer;
}
.pos_relative{
  position:relative;
}
.template-header-row
{
background-color: #ededed;
  min-height: 40px !important;
  align-items: center;  
  padding-top: 10px;
}
.inventoryAlign{
  display: inline;
  padding: 0px 5px;
  width:auto;
}
.pdfTemplatepadding{
  padding-top: 20px;
}
.paymentMsg{
  font-weight: bold;
  font-size: 21px;
} 
.marginTop2{
  margin-top: 2% !important;
}

.progressTopPadding{
  margin-top: 10% !important;
}
.marginTop3{
  margin-top: 3%!important;
}
.noRecordPadding{
  padding-left: 45%;
  padding-top: 3%;
  font-size: 107%;
}
::ng-deep .mat-tooltip  {
    white-space: pre-line    !important;
}
.marginBottom4{
  margin-bottom: 7% !important;
}
.displayInline{
  display: inline !important;
}
.tableCell{
  display: inline;
  padding:15px 5px 2px 4px;
}
.flex36{
  flex:0 1 36px !important;
}
.flex50{
  flex:0 1 50px !important;
}
.marginLeft10px{
  margin-left: 10px !important;
}
.marginLeft21px{
  /*margin-left: 65px !important;*/
  margin-left: 21px !important;
  width: 65% !important;
}
.marginTopM10px{
  margin-top:-10px !important;
}
.marginTop16{
  margin-top:16% !important;
}
 .marginTop1{
  margin-top:1% !important;
}
.mat-icon-button[disabled]{
  cursor: not-allowed !important; 
}
.paddingTop30px{
  padding-top:30px !important;
}
.noTemplateFound{
  font-size:30px;
  padding:126px;
}
.noTemplateHeight{
  height:375px !important;
}
.fontColor{
  color:gray !important;
}
.marginTop5{
  margin-top: 5% !important;
}
.marginBottom3{
  margin-bottom: 3%!important;
}
.width120px{
  width: 120px!important;
}
.fontWeightNormal{
  font-weight: normal !important; 
 
}
.boxminheight
{
  height: 45vh;
 
 
}
.discountDivColor .mat-select-value
{
  color:crimson !important;
}
.discountDivColor
{
  color:crimson !important;
 
}
.marginBottom2{
  margin-bottom: 2%!important;
}
.labelStyleForDiv{
 padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
}
.floatingbtn, .floatingbtn1,.floatingbtn2,.floatingbtn3,.floatingbtn4,.floatingbtn5
{
  position: fixed !important;
  left: 7px;
}
.floatingbtn1
{
top: 12%;
}.floatingbtn2
{
top: 18%;
}
.floatingbtn3
{
top: 24%;
}
.floatingbtn4
{
top: 30%;
}
.floatingbtn5
{
top: 36%;
}

.mat-expansion-panel-custom-template{
  padding-left:0px !important;
  padding-right:0px !important;
}
.marginRight20{
  margin-right: 20px!important
}
.fontSize12
{
  font-size: 12px !important;
}
.fontSize14
{
  font-size: 14px !important;
}
.paddingRight18{
  padding-right: 18px !important;
}
.paddingRight10{
  padding-right: 10px !important;
}
.width12px{
  width: 12px !important;
  float:left;
}
.marginTop7px{
  margin-top: 7px !important;
}
.matCardHeight{
  height:100%;
}
.btn_addItem{
  width:100%!important;
  text-align: left!important;
  background:none;
  border:none;
  line-height: 30px !important;
}
.btn_addItem:active,.btn_addItem:hover,.btn_addItem:focus{
  background: none !important;
  outline: none !important;
}
.addItem_icon{
  float: left;
  padding-top:2px;
} 
.my-tooltip {
    white-space: pre-line;
}
.mat-column-aPIUrlCode{
    flex: 0 0 36%;
}
.mat-column-aPIUrlValue{
  flex: 0 0 54%;

} 

.marginLeft3{
      margin-left: 3%;
  }
  .matTabFlex{
    flex: 1.2;
    display: inline-flex;
    overflow: auto;
    width: 100%;
  }
.paddingTop15{
padding-top: 15px;
}


/* centre align an div */
/* give this to the parent div */
.parent-div{
  padding: 0% 0;
}
/* give this to the child div which has to be vertically aligned */
.child-div{
  /*padding: 18% 0;*/
  padding-top: 17px !important;
}
.paddingLeft32{
  padding-left: 32px!important;
}
.flex170{
  flex:0 1 170px !important;
}
.flex126{
  flex:0 1 126px !important;
}
.flex237{
  flex:0 1 237px !important;
}
.flex206{
  flex:0 1 206px !important;
}
.flex188{
  flex:0 1 188px !important;
}
.flex183{
  flex:0 1 183px !important;
}
.wordBreak{
  word-wrap: break-word;
  word-break: break-all;
}
.flex3px{
  flex:0 1 3px !important;
}
.paddingTopBottom1pert
{
      padding-top: 1%;
    padding-bottom: 1%;

}
.paddingTop3Bottom1pert
{
      padding-top: 3%;
    padding-bottom: 1%;

}
.dlp-flx{
display: flex;
}
.mat-icon-button-custom{
  padding: 0;
    min-width: 0;
    width: 28px !important;
    height: 28px !important;
    flex-shrink: 0;
    line-height: 30px !important;
    border-radius: 44% !important;
}
.myImg {
    max-width: 75px;
    max-height: 75px;
}

.myImgNew {
    max-width: 100px;
    max-height: 100px;
}

.myDivBorder{
   border-bottom: solid;
       border-width: thin;
   border-color: #b5b0b0;
}
.padding5px{
  padding: 5px;
}
.paddingBottom20px{
      padding-bottom: 20px;
}
.panelGrey{
  background-color: #c7b8b8;  
}
.paddingBottom10px{
      padding-bottom: 10px !important;
}
.panelSandal{
  background-color: #e8d9c6; 
}

.fileAttachFontSize,.ng-trigger.ng-trigger-indicatorRotate.ng-star-inserted{
  float: right;
  margin-top: -1%;
  margin-right: 1%;
}

.col-lg-2.cardheadertitle.mat-slide-toggle-content{
  font-size: 16px !important;
}

.textBlue{
  color: #374fd8 !important;
  background-color: #bdbcbc;
  font-weight: bold !important;  
}

.textGreen{
  color: #068804 !important;
  background-color: #dcd7d7;
  font-weight: bold !important;
}

.textWhite{
  color: #068804 !important;
  background-color: #dcd7d7;
  font-weight: bold !important;
}

.detailHeaderPadding{
padding-top: 2% !important
}

.artWorkTimelineClass{
  padding-top: 2% !important;
  padding-bottom: 1%;
  margin-right: 0px !important
}

.paddingBottom2Percent
{
padding-bottom: 2% !important;
}  
.notification{
  width: 250px !important;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.image20px20px
{
  width: 20px;
  height: 20px;
}
.history
{
  max-height: 500px;
  overflow-y: auto;


}
.assetTopPadding{
  padding-top:10px
}
html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

.scroll_artwork_div{
    overflow-y: scroll;
    height: 380px;
}
.marginRight_Zero{
  margin-right: 0px;

}
.mobile-icons{
  background-color: #3b78e7;
  margin-top: 35px;
  width: 47px;
box-shadow: 2px 3px 4px 0px rgba(173, 164, 164, 0.65), 0 2px 2px 0 rgba(0, 0, 0, 0.25), 1px 4px 3px 0 rgba(0, 0, 0, 0.15);
}
.mobile-icons .mat-icon{
color: white;
font-size: 20px;
cursor:pointer;
margin-top: 4px;

}
.settingstab-mobile .vertIcon 
{
 color:white; 
 position: fixed;
 cursor:pointer;
}
.mobileDropDown{
     top: 6px ! important;
     right: 21px ! important;
}
 
.toggleFontSize.mat-slide-toggle.mat-slide-toggle-label.mat-slide-toggle-content{
  font-size: 15px !important;

}

.viewTrackingNum{
    font-size: 18px !important;
    font-weight: 500;
    padding-left: 5px;
}

.viewTrackingNumHeader{
    font-size: 14px !important;
    padding: 5px;
}
.paddingTop10px
{
  padding-top: 10px !important;
}  
.wordBreakReference{
  word-break: break-word;
  padding-right: 10px; 
} 
.sortOptions{
 cursor: pointer;
 min-width: 205px;
 display: inline-block;  
}
.sortOptionsTitle{
  justify-content: flex-start;
  width: 50%; 
  float: left;
}
.sortOptionsIcons{
  justify-content: flex-end; 
  display: flex; 
  width: 50%

}
.marginTop3px{
  margin-top: 3px !important;
}
.imageSiza
{
  max-width: 70%;
  max-height: 70%; 
  border: 1px solid;
  display: block;
}
.imageSizbWithFields
{
  width: 65%;
  height: 65%; 
  border: 0px solid;
  display: block;
  margin-bottom: 20% !important;
}

.imageSizb
{
  width: 65%;
  height: 65%; 
  border: 0px solid;
  display: block;
  margin-bottom: 5% !important;
}
.noRecordAlign{
  text-align: center;
  font-size: 107%;
}
.historyLabel{
  padding-right: 1% ! important;
  padding-left: 1% ! important;
  font-weight: normal;
}
.historyButton{
  padding-left: 40%;
  margin-top: 20%;
}
.marginTop20
{
  margin-top: 19px !important;
}
.borderImg{
  margin-top: 20px;
  border: 1px solid;
  display: block;

}
.versionImgBorder{
  margin-top: 20px;
  border: 1px solid;
}
.shipmentHint{
  margin-left: 2%;
  margin-bottom: 2%;
  padding-top: 5%;
}

.textAlignCenter{
   margin-left: 520px;  
}
.textAlignMargin{
  margin-left: 500px; 
}
.matCardHeight{
  margin-top: 2%;
  height: 570px; 
}
.buttonAlign{
  margin-left: 420px;
  }
.width130px{
  width: 130px !important;
}
.left100px{
  left: 100px !important;
}
.marginTopPx{
  margin-top: 16px !important;
  left: 15px !important;
}
.trackingFlex{
  flex: 0 1 160px;
  
}
.marginAlign
{
  margin-left: 25px;
  padding-top: 15px;
}
input.number::-webkit-outer-spin-button,
input.number::-webkit-inner-spin-button {
  display: none;
}
.tableBorder{
  border-collapse: collapse;
  padding: 5px;
}
.width100Percent
{
  width:100%;
  table-layout:auto;margin-bottom: 0px;
  border: thin;
}
.tableWidth{
  table-layout:auto;
  padding-left: 14px;
  margin-left: 18px;
  margin-right: 12px;
  width: 805px;
  margin-top: 12px;
}
.borderBottom{
  border-width: thin;
  border-bottom-width: 3px;
}
.height41px
{
  height: 41px;
  width:100%;
  border-bottom-style: none;
}
.bottomPadding25Px{
  padding-bottom: 25px;
  background-color: rgb(201, 197, 197);
}
.titleStyle{
  background-color: white;
  text-align: center;border: solid;
  border-width: thin;
  border-bottom-style: none; 
}
.borderNone
{
   border:solid;
   border-width: thin;
   border-bottom-style: none;
}


 
/*.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: gray;
    opacity: 0.85 !important;
  }
  .mat-tab-header{
    height: 3em !important;
  }
}*/
.dark-backdrop {
    background: gray;
    opacity: 0.20 !important;
  }
.pdfPreviewSubTotal{
    width: 35%;
    margin-left: 2%;
    text-align: right;
    margin-top: 2%;
    padding-right: 5px;
} 
. 
.alignBorder{
  border: 1px solid black;
}
.paddingBottom25{
  padding-bottom: 25px;
}

.mat-expansion-panel-header-title
{
  margin-right:4px !important;
}

.borderstyle
{
  border:solid;
   border-width: thin;
   border-bottom-style: none;
   border-left-style:none;
   border-right-style:none;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F8F8F8;
}

::-webkit-scrollbar
{
	width: 8px;
	background-color: #3b78e7;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #3b78e7;
}
mat-dialog-container#feedbackpopup {
    width: 70vw;
}
mat-dialog-container#changepasspopup {
    width: 35vw;
}
mat-dialog-container#createtaskcomp
{
  width: 50vw;
}
.apidocument_resp
{
  margin-top:1%;
  margin-bottom:1%;
  width:65%;
}
table.available_api_ser
{
  width:25%;
}
.mat-tab-body-content.ng-trigger.ng-trigger-translateTab {
    overflow: hidden;
}
.tableBorderOrg{
    border: solid;
    border-width: thin;
    border-right-style: none;
}
.solidborder{
      border: 1px solid black;
}
.padding2Px{
  padding: 2px;
}
.tableLayoutFixed{
  table-layout: fixed;
}
.fontWeightBold{
  font-weight: bold;
}
.wordWrap{
  white-space: initial;
  word-wrap: break-word;
}
.greenText{
  color:green;
}
.redText{
  color:red;
}
.iconMargin{
  margin-right: 7%; 
  cursor: pointer;
}
.flex380px{
  flex:0 1 380px !important;
}
.height100Px
{
  height: 480px;
}
.marginTop10
{
  margin-top: 10% !important;
}
 .mat-row:nth-child(even){
          background-color:white;
          }

   .mat-row:nth-child(odd){
          background-color:azure;
          }
.marginTop12
{
  margin-top: 12% ! important;
}

.mat-card-view-breadcrumb{
   padding-top: 0px !important;
  padding-bottom: 0px!important;
  margin-bottom: 5px;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

.BreadCrumbList:before, .BreadCrumbList:after {
  content: ' ';
  display: table;
}
.BreadCrumbList:after {
  clear: both;
}



.innerBreadCrumb {
  margin: 0 auto;
}

.breadcrumbs {
  
  background-color: #f5f5f5;
}

.breadcrumbs ul {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.breadcrumbs li {
  float: left;
  width: 15%;
}

.breadcrumbs a {
  position: relative;
  display: block;
   padding:4px;
  padding-right: 0 !important;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #aaa;
  cursor: pointer;
}

.breadcrumbs a:hover {
  background: #eee;
}

.breadcrumbs a.active {
  color: #777;
  background-color: #fafafa;
}

.breadcrumbs a span:first-child {
  display: inline-block;
  padding: 2px;
  margin-right: 5px;
}



.breadcrumbs a:before,
.breadcrumbs a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
}

.breadcrumbs a:before {
  margin-left: 1px;
  border-left-color: #d5d5d5;
}

.breadcrumbs a:after {
  border-left-color: #f5f5f5;
}

.breadcrumbs a:hover:after {
  border-left-color: #eee;
}

.breadcrumbs a.active:after {
  border-left-color: #fafafa;
}
.wordBreakAll{
  word-break: break-all !important;
  word-wrap: break-word !important;
}
.marginTop6{
    margin-top: 6% ! important;
}
.paddingAlign{
    padding-left: 45px !important;
    padding-top: 15px !important;
}
.paddingLeft20{
  padding-left: 20px !important;
}
.marginAlignment{
  margin-left: 5% !important;   
  margin-top: 20px !important;
}
.itemRow:nth-child(odd){
    background-color:white;
    margin-left: 15px !important;
 }

.itemRow:nth-child(even){
    background-color:azure;
    margin-left: 15px !important;
 }
.noBorder{
  border:none !important;
}
.paddingRight0Px{
      padding-right: 0px !important;
}
.width20{
  width: 20% !important;
}
.width30{
  width: 30% !important;
}
.width35{
  width: 35% !important;
}
.width60{
  width: 60% !important;
}
.width90{
  width:90% !important;
}
.width98{
  width:98.5% !important;
}
.lineItemWidth:nth-child(odd){
  width:98.5%;
}
.lineItemWidth:nth-child(even){
  width:98.5%;
} 
.packRow:nth-child(odd){
    background-color:white;
    width: 99%;

 }

.packRow:nth-child(even){
    background-color:azure;
    width: 99%;
 }
 .stockRow:nth-child(odd){
    background-color:white;
 }

.stockRow:nth-child(even){
    background-color:azure;
 }
.borderBottomRow{
      margin-bottom: 0px !important;
}
.gridColor:nth-child(even){
    background-color:white;
 }

.gridColor:nth-child(odd){
    background-color:azure;
 }
.gridWidth{
  width: 98% !important;
}
.marginLeft2{
  margin-left: 2% !important;
}
.paddingRight1{
  padding-right: 1% !important;
}
.width97{
  width:97% !important;
}
.leftMargin{
  margin-left: 1.5% !important
}

.width100{
  width: 100% !important;
}
.alignEnd{
  text-align: end !important;
}
.bottomPadding1{
  padding-bottom: 1% !important;
}
.reportValueAlignCenter{
  text-align: center !important;
  justify-content: center !important;
  }

.arrowDropUpPadding{
    width: 0px !important;
    height: 40px !important;
    padding-right: 0px !important;
    padding-top: 2px !important;
    cursor: pointer !important;
}
.arrowDropDownPadding{
    height: 0px !important;
    padding-top: 0px !important;
    width: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 14px !important;
    cursor: pointer !important;
}
.icon_size{    font-size: 18px;
    margin-top: 6px;
    vertical-align: middle;
    color: #c39d2d;}

 .cartBadge {
    position: absolute;
    top: -5px;
    right: -1px;
    padding: 5px 7px;
    border-radius: 50%;
    background: dodgerblue;
    color: white;
}
.textAlignLeft{
  text-align: left;
}
.textAlignRight{
  text-align: left;
}
.artworkChatScroll{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 167vh;
}
.paddingRight2{
  padding-right: 2% !important;
}
.autosearchcontacts
{
  min-height: auto !important;
 
}
.autosearchcontacts .mat-header-cell {
  color: rgb(0, 0, 0);
}
.flex30Percent{
  flex: 0 1 30% !important;
}

.flex15Percent{
  flex: 0 1 15% !important;
}
.flex20Percent{
  flex: 0 1 20% !important;
}
.flex10Percent{
  flex: 0 1 10% !important;
}
.versionTabTopBorder{
    border-top: solid;
    border-width: 0.5px;
    border-color: lightgrey;
}

.fontBolder{
  font-weight: bolder;
}
.fontNormal{
  font-weight: normal;
}

/* added to align the menu in Sort By */
.sortWordWrap{
    word-wrap: break-word;
    white-space: pre-wrap;
}
.logincenter
{
  margin:auto;
}
.vertical-align-middle{
  vertical-align:middle !important;
}
a{
  color:#337ab7 !important;
}
 .table-striped tbody tr:nth-of-type(even){
  background-color:rgba(0,0,0,.05);
 }
.table-striped tbody tr:nth-of-type(odd){
  background-color:white !important;
 }
 
 .float-left{
  float: left;
}
.table thead th{
   vertical-align:middle !important;
   border-bottom: 1px solid #dee2e6 !important;
}
.table td{
   vertical-align:middle !important;
}
.cdk-table-class td{
  padding: 0.40rem !important;
}
.cdk-table-class p{
  margin-bottom: 0px !important;
}
.erp-money-class{
text-align: right !important;
   
  justify-content: flex-end; 
}
/* Table Modified CSS*/
table.tablemodified th
{
  padding: 0px 5px !important;
}
table.tablemodified td
{
  /*padding: 5px !important;
  vertical-align: top !important;*/
      padding: 2px !important;
    vertical-align: middle !important;
    text-align: center !important;
}
table.tablemodified .mat-hint p.marginBottom2
{
float: left;
}
table.tablemodified .discountDivColor
{
  width: 100%;
}
table.tablemodified .mat-form-field-hint-spacer
{
  display: none;
}
table.tablemodified .mat-form-field-infix
{
padding: 0.2em 0 0.8em 0;
}

table.tablemodified .child-div
{
padding-top: 10px !important; 
}
table.tablemodified button.mat-button .mat-select-arrow
{
  margin: 2px 4px !important;
} 

 table.erp-veiw-template-table td p .material-icons
{
  font-size: 20px;
  height: 40px !important;
  width: 40px !important;
} 

 table.erp-veiw-template-table td p .mat-icon
{
 
  height: 20px !important;
  width: 20px !important;
} 
 table.erp-veiw-template-table td .mat-icon-button{
  height: 40px !important;
  width: 40px !important;
 }
  table.erp-veiw-template-table td .mat-button{
  padding: 0 0px;
  min-width: 40px !important;
 }
 table.tablemodified thead tr .icon-width-class{
  width: 130px;
 }
 tr.mat-footer-row {
  font-weight: bold;
}
table.pricetablemodified th
{
  padding: 0px 5px !important;
}
table.pricetablemodified td
{
  /*padding: 5px !important;
  vertical-align: top !important;*/
      padding: 2px !important;
    vertical-align: middle !important;
    /*text-align: center !important;*/
}
table.pricetablemodified .mat-hint p.marginBottom2
{
float: left;
}
table.pricetablemodified .mat-form-field-hint-spacer
{
  display: none;
}
table.pricetablemodified .mat-form-field-infix
{
padding: 0.2em 0 0.8em 0;
}

table.pricetablemodified .child-div
{
padding-top: 10px !important; 
}

/*button.mat-button.mat-icon-button{
  outline: none;
}*/
button.mat-button.cdk-focused.cdk-program-focused{
  outline: none;
}
button.mat-icon-button.cdk-focused.cdk-program-focused{
  outline: none;
}
button.mat-icon-button.cdk-focused.cdk-mouse-focused{
  outline:none;
}
button.mat-button.cdk-focused.cdk-mouse-focused{
  outline: none;
}
button.mat-button.mat-icon-button.cdk-focused.cdk-touch-focused{
  outline: none;
}
button.mat-button.cdk-focused.cdk-touch-focused{
  outline: none;
}
button.mat-calendar-previous-button.mat-icon-button.cdk-focused.cdk-touch-focused{
  outline: none;
}
button.mat-calendar-next-button.mat-icon-button.cdk-focused.cdk-touch-focused{
  outline: none;
}
button.buttonWidth.mat-icon-button.mat-primary.cdk-focused.cdk-touch-focused{
  outline: none;
}
button.mat-raised-button.mat-primary.cdk-focused.cdk-program-focused{
  outline: none;
}
button.buttonWidth.mat-raised-button.mat-primary.cdk-focused.cdk-mouse-focused{
  outline: none;
}
button.mat-raised-button.mat-primary:focus{
  outline: none;
}
.angular-editor-textarea{
    height: 22rem !important;

}
.material-icons.color_green 
{ 
  color: green; 
  padding-left: 40px;
}
.material-icons.color_red {
 color: red;
 padding-left: 15px;
} 
.alignAtPageCenter{
    position: absolute !important;
    top: 50%;
    left: 30%;
    font-size: 20px;
} 

/* Scroll for sidenav */
.fuse-aside-folded .aside-menu
{
  overflow: hidden ;
}
.fuse-aside-expanded .aside-menu
{
    overflow-y: auto;
    height: 100vh;
    overflow-x: hidden;
}

.aside-menu::-webkit-scrollbar-thumb
{
  scrollbar-color: #aaa;
  background: #aaa;
}
.aside-menu ul li span
{
  white-space: nowrap;
    color: #525151;
    padding-left: 8px;
    font-size: 15px;
}
.aside-menu ul li a.sub-link span
{
 font-size: 14px; 
}
.aside-menu .material-icons
{
  font-size: 22px !important;
}
.aside-menu ul li a.sub-link .material-icons
{
  font-size: 18px !important;
}
.nav-link:hover>.material-icons
{
color: rgb(53, 135, 200) !important;
}
.sub-link
{
  height: 3.2rem !important;
}
.mat-snack-bar-container{
  margin-top : 50px !important;
}

/* Timepicker issue so please need to reduce the z-index */
/* .cdk-overlay-container{
z-index: 1045 !important;   
} */
.cdk-overlay-container{
  z-index: 1045 !important;   
  }
  .timepicker,.timepicker-overlay{
    z-index: 1046 !important;   
  }
.ps>.ps__scrollbar-x-rail{
  display: none !important;
}
.aside-menu .mat-menu-item .mat-icon-no-color, .mat-menu-item-submenu-trigger::after{
color:#fff !important;
font-size:14px !important;
}
.aside-menu .menu_add_btn
{
margin-top:-24px;
    margin-right: 14px;
        border: 0;
    text-decoration: none;
    outline: none;
}

.mat-raised-button{
  min-width: 103px !important;
}
html.cdk-global-scrollblock{
top:0px !important;
}

.content .mat-drawer-container
{
      background-color: #f8f8fa;
}
@media (min-width: 1600px)
{
  .settingstab .mat-icon
  {
    margin:0px !important;
        font-weight: 500;

  }
}
.settingsmenu .mat-accordion .mat-expansion-panel:last-of-type, .mat-accordion .mat-expansion-panel:first-of-type
{
      border-radius: 0px !important;
}

/*  9005 - Login page Css starts */
.login_wrapper {
    top: 0;
    width: 100%;
    bottom: 0;
    min-height: 100vh;
    z-index: 9;
    opacity: 1;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
        padding: 10% 0 0% 0;
   
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-size: cover;
}
.textwrapper
{
display: block;
    align-items: center;
    min-height: 90vh;
        margin: auto 0;
 /*   max-width: 1170px;*/
    width: 100%;
}
.wpawll-headline {
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    color: #232323;
    line-height: 50px;
    margin-bottom: 30px;
    width:100%;
    text-transform: uppercase;
    color:#004ba7;
    font-weight: 600;
}
.wpawll-headline span.colorblack
{
color:#010000;
 font-family: 'Montserrat', sans-serif;
}
.wpawll-headline span.colorred
{
  color: #dc4b3e;
   font-family: 'Montserrat', sans-serif;
}
.wpawll-tagline {
   color: #7b7b7b;
    font-size: 19px;
    margin-top: 5px;
    margin-bottom: 30px;
    width: 84%;
    font-family: 'Raleway', sans-serif;
}
.signin-button
{
  background: #0052b6;
    font-size: 14px;
    color: #fff;
    border: none;
    height: auto;
    line-height: 1;
    padding: 12px 30px;
    text-shadow: none;
    box-shadow: none;
   
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    font-weight: 600;
    width:120px !important;
}
.fliter-drop{
  margin-right: -230px;
}
.googlein-button
{
  background: #db4437;
    font-size: 14px;
    color: #fff;
    border: none;
    height: auto;
    line-height: 1;
    padding: 12px 30px;
    text-shadow: none;
    box-shadow: none;
    
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    font-weight: 600;
    width:120px !important;
}
.logincenter .mat-card
{
-webkit-box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.37) !important;
    -moz-box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.37) !important;
    -ms-box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.37) !important;
    -o-box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.37) !important;
    box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.37) !important;
}

.footer-wrapper
{
      padding: 10px 50px;
    text-align: center;
    display: inline-block;
        width: 100%;
        font-family: 'Raleway', sans-serif;
}
a.producttext
{
color:#201f1f !important;
}
a.poweredtext
{
color :#af0a03 !important;
font-weight:500;
}
.buttonalgincenter
{
  text-align: center;
}
.powertext
{
  width: 60%;
    text-align: right;
    float: left;
    padding-left: 20%;
    padding-top: 10px;
}
.or_text {
    position: relative;
    margin: 20px 0;
}
.or_text:after {
    display: block;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    background: #ddd;
}
.hammer{

  display: none;
}
.or_text span{
    padding: 0 15px;
    display: inline-block;
    background: #ffffff;
    position: relative;
    z-index: 1;
}
.social-list {
    margin: 10px 0;
    padding: 0;
}

/* Login Page Css Ends */

.sign-in-loaded #wrapper
{
  padding-left:0px !important;
}

.sign-in-loaded .the-footer
{
  display: none 
}

.sign-in-loaded .adjustcontainer
{
  padding-top:0px !important;
  margin-top: 0px;
}

.sign-in-loaded .adjustcontainer .mobileTitlePadding
{
  padding:0px !important;
}
.alignRight mat-form-field.mat-form-field {
    width: 120px !important;
}
.logincenter .mat-card
{
      border-radius: 10px !important;
}

.table th, .table td{
  padding: 0.6rem 1.2rem !important;
}

.inValidBg .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgb(251, 0, 0) !important;
}
.validBg .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0,0,0,.42) !important;
}
.closeIcon{
  padding-left: 50px;
  cursor: pointer; 
}

.heightZero{
  height: 0px !important;
}
.height48Px{
  height: 48px !important;
}
.parentDescrip{
  cursor:none;
}
.parentDescrip .childDescrip{
 visibility: hidden;
  width: 19%;
  background-color: #8c8b8b;
  color: white;
  text-align: center;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  font-size:small;
}
.res-dis{
  padding-top: 15px;
}
.parentDescrip:hover .childDescrip{
   visibility: visible;
}

.marginAnnounce{
  margin-top: 10px;
}

.announcescroll
{
  height: 55vh;
  overflow-y: scroll;
}

a.attendanceClick:hover
{
  color:darkblue !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

a.attendanceClick.activeAttendanceClass {
  color:darkblue !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

@media (min-height: -width: 768px){
 .searchdrop, .searchmod {
    display: block;
    /*background-color: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.12);*/
    height: 36px;
  }
}
/* 9079 starts*/




@media (max-width: 768px){
  
  .mat-action-row.buttons {
    display: block;
}
    #mobile-menu-toggler {
    margin-left: 80px;
    margin-top: 7px;
}
  .hammer{
  display: block;
}
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.cardheadertitle span {
    font-size: 22px;
}

.logo {
    margin-top: -16px;
    float:left;
}
.login_wrapper {
    padding-top: 0px !important;
}
.wpawll-headline{
text-align: center;
font-size: 30px ;
    margin-bottom: 15px;
}
.sign-in-loaded .adjustcontainer .mobileTitlePadding{
margin-top:0px !important;
}
img.login-img {
    display: none;
}
.topheader {
    height: 48px !important;
    left: 0px !important;
    top: 0px;
    text-align: center;
    /* display: block; */
}
/* 9079 Ends*/
  .searchdrop, .searchmod {
    display: block;
    /*background-color: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.12);*/
    height: 0px;
  }
  .content {
    margin-top: 18%;
  }
  .togglemenu {
    padding-top: 3px;
  }
  .settingstab {
    padding-left: 10%;
    /*margin-top: 15px;
*/
  }
  .height60{
    height: 57% !important
  }
  .fr-wrapper{
    height:73% !important;
    overflow-x: auto;
  }
.checkboxTop
{
 margin-top: 15px !important; 
}
.popupOverfLow{
  overflow-y: auto;
  max-height: 520px;
}
  input.headersearch{
  width: 310px;
  margin-top: 30px;
  }
  .settingstab-mobile .vertIcon{

    display:none;
  }
  .dis-blk {
    display: block !important;
}
  .mb-pd-0{

    padding: 0px !important;
  }
.mat-card{
  padding-top: 15px !important;
}
  .mb-mr-0{
 margin: 0px !important;
    
  }
  .mat-dialog-container{

    padding: 15px !important;
  }

.txtcenter{

  text-align: center;
}
.pro-code{
 overflow-x: scroll;
}
.fliter-drop{
  margin-right: -230px;
}
.res-dis .mat-form-field {
    display: contents;
}
img.img-hris {
    display: none;
}
.dlp-flx{
display: block;
}
.buttons{text-align: center;}
.aply-leave .row-label {
    margin-left: -60px;
}
.button-row {
text-align: center;
padding: 0px 0px 15px 8px;
}
.edit-popup {
    margin-right: -34px;
    margin-top: 24px;
}
.quick-info {
    width: 250px !important;
}
.btn-rev {
    margin-left: -21px !important;
}
 .example-full-width{

  width:100% !important; 
 }
 .upload-img{max-height:210px !important; max-width: 246px; border: 1px solid; display: block;}
 .mat-select-panel{

      max-width: 211px !important;

 }


}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px){
 
 .mat-card{
  padding: 8px;
 }
    mat-card-title.mat-card-title{
      font-size: 22px;
    }
    .cardheadertitle span {
    font-size: 16px;
}
  input.headersearch{
  width: 270px;

  }
  .data mat-card-title.mat-card-title {
    padding: 15px 0px 0px 8px;
}
  .margin-left.example-container.mat-elevation-z8.viewcontacts{
  margin-left: -5px !important;
  }
  .wpawll-headline{
font-size: 26px;
}
.fliter-drop{
  margin-right: -181px;
}
.buttons{text-align: center;
  }
  .buttons button.mat-primary {
    margin-bottom: 15px;
}
.table-label-left-align {
    text-align: right;
    padding: 0px;
    margin-top: -37px;
}
.button-row button {
    margin-bottom: 15px;
}
.edit-popup {
    margin-right: -128px;
    margin-top: 38px;
}
.quick-info {
    width: 150px !important;
}

#mobile-menu-toggler {
    margin-left: 36px;
    margin-top: 7px;
}
}
@media only screen 
  and (min-device-width: 561px) 
  and (max-device-width: 769px){

.aply-leave .row-label {
    margin-left: -412px;
}
 }
@media (max-width: 990px){
  .edit-menu-role {
    display: none !important;
}
  main.active aside#aside {
    visibility: visible;
}
  .move-to-left {
  transform: translateX(0px);
    transition: transform .7s ease-in-out;
}
  .settingstab.nopadding {
    background: no-repeat;
}
  .settingstab .mat-icon{

  color: #ffffff;
 }
.settingstab {
    padding-right: 0px !important;
    display: flex;
    width: 100%;
    margin-left: -15px;
    margin-top: -26px !important;
    background: #2196f3;
    color: wheat;
    height: 42px;
        padding-right: 30px !important;
}
.settingstab.nopadding button {
    display: none;
}
  .main-head{
display: contents;
  }

.mobileTitlePadding {
    margin-top: 100px ! important;
}
}
.bar {
    display: block;
    height: 3px;
    width: 35px;
    background-color: #2196f3;
    margin: 7px auto;
}
.middle {
    margin: 0 auto;
}
.hammer {
    padding-right: 10px;
}
.mobile-menu {
    --tw-border-opacity: 1;
    border-color: rgba(46,81,187,var(--tw-border-opacity));
    border-bottom-width: 1px;
    margin: -1.25rem -2rem 1.5rem;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}
  .mobile-menu-bar{
    margin: 0px !important;
  }

    .mobile-menu { border-bottom-color: #2196f3!important;
    margin: 0px !important;
}
.hammer
{
  padding-right: 10px;
}
.hammer.active .top {
  -webkit-transform: translateY(15px) rotateZ(45deg);
  -moz-transform: translateY(15px) rotateZ(45deg);
  -ms-transform: translateY(15px) rotateZ(45deg);
  -o-transform: translateY(15px) rotateZ(45deg);
  transform: translateY(15px) rotateZ(45deg);
}

.hammer.active .bottom {
  -webkit-transform: translateY(-5px) rotateZ(-45deg);
  -moz-transform: translateY(-5px) rotateZ(-45deg);
  -ms-transform: translateY(-5px) rotateZ(-45deg);
  -o-transform: translateY(-5px) rotateZ(-45deg);
  transform: translateY(-5px) rotateZ(-45deg);
}

.hammer.active .middle {
  width: 0;
}

.bar {
  -webkit-transition: all .7s ease;
  -moz-transition: all .7s ease;
  -ms-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}



@media screen and (max-width: 600px) {
   .table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .table thead th
  
  .table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  .table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  .table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .table td:last-child {
    border-bottom: 0;
  }
}
.validateBg{
  background: #ff130814;
}
.mat-expansion-indicator{
  margin-top: 0px !important;
}
.mat-expansion-panel-content .mat-expansion-panel-body{
  padding: 10px 5px 10px !important;
}
.taskDesc350width{
  min-width: 350px !important;
}
.task200width{
  min-width: 200px !important;
}
.font_size_20{
  font-size: 20px !important;
}
.mat_card_title{
  line-height: 20px;
    margin-right: 5px;
}
ngx-treeview-item, .treeview-header input, .treeview-item i,.treeview-item .form-check input.form-check-input,.treeview-item .form-check label{
font-size: 13px !important;
padding-left: 0px !important;
}
.treeview-item .form-check input.form-check-input{
  display: none;
}
ngx-dropdown-treeview button.btn.dropdown-toggle{
  font-size: 13px;
    padding: 0px;
    background: none !important;
    color: #000 !important;
    line-height: 22px;
    height: 40px;
    border: 0px;
    border-bottom: 1px solid #949494 !important;
    border-radius: 0px;
    width: 100%;
}
.treeViewCls{
  z-index: 1;
  position: absolute !important;
}
ngx-dropdown-treeview.treeViewCls ngx-treeview-item i{
  width: 0.5rem !important;
}
ngx-dropdown-treeview.treeViewCls  .treeview-header .dropdown-divider{
  display: none !important;
}
ngx-dropdown-treeview.treeViewCls  .dropdown-toggle:focus{    box-shadow: none !important;}
ngx-dropdown-treeview.treeViewCls  button.btn.dropdown-toggle{    color: #ccc0 !important;}
ngx-dropdown-treeview.treeViewCls button.dropdown-toggle::after{    color: #757575 !important;}

ngx-dropdown-treeview.treeViewCls.noborder button.btn.dropdown-toggle{
    border-bottom: 0px solid #949494 !important;
    
}
.inputsmall .mat-form-field-infix{
  padding: 3px 0px !important;
  border-width: 0px !important;
  line-height: 30px !important;
}
table thead tr th{
  font-size: 14px !important;
}
table tbody tr td{
  font-size: 13px !important;
}
.chip-list{
  width: 100% !important;
}
.chip-list div.mat-form-field-flex{
  background: #fff;
  padding: 0px;
} 
.chip-list input.mat-chip-input{    margin-left: 3px;
  margin-top: -15px;}
.chip-list label.mat-form-field-label{
  margin-top: 0px !important;
}
.chip-list .mat-standard-chip .mat-chip-remove .mat-icon {
  width: 15px;
  height: 15px;
  font-size: 15px;
}
.chip-list mat-chip{
  padding: 0px 7px;
    min-height: 24px;
    line-height: 24px;
    font-size: 13px;
}
.odd{
  background: #f0ffff;
}
.odd .mat-form-field-appearance-legacy .mat-form-field-wrapper , .even .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  margin-bottom: -5px;
}
.withoutBorder .mat-form-field-underline{
  height: 0px !important;
  transform: scale3d(0, 1.0001, 1);
}
.odd, .even{
  border-bottom: 1px solid #e7e7e7;
}
.chip-list .mat-form-field-underline{
  bottom: 0em !important;
}
.chip-list .mat-form-field-infix{margin-top: 0px;
  padding-top: 0px;}
  .chip-list .mat-form-field-wrapper {
    padding-bottom: 0em !important;
}
.mat-icon-button[disabled] mat-icon{
  cursor: not-allowed;
}
.report_cls .mat-header-cell{
  font-size: 11px !important;
  font-weight: 600;
}
.report_cls .arrowDropDownPadding{
  position: absolute;
}

.iconDiv{
  width: 0px;
    margin-top: 3px;
}
.overAllDiv{
  display: flex;
}
.titleDiv{
  line-height: 20px;
}

.centerAlignment .mat-sort-header-container {
  margin:0 auto;
  width:140px;

}

.cntrAlign .mat-sort-header-container {
  margin:0 auto;
  width:72px;

}
.matAlignCenter{
  text-align: center;
  justify-content:center;
}

app-trainingpopup.ng-star-inserted mat-error .ng-star-inserted {
  margin-top: -18px;
  margin-bottom: 15px;
}
app-trainingpopup label.mat-form-field-label {
  font-size: 14px !important;
}

app-trainingpopup label.mat-form-field-label {
  transform: translateY(-1.28125em) scale(1.0) perspective(100px) translateZ(0.001px) !important;
}