  .settingstab
{
 display: inline-flex;
 justify-content: flex-end;
 padding-top: 12px !important;
}
.settingstab-mobile{
  display: none;
}
.mobile-label
{
	display:none;
}