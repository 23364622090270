  .settingstab
{
 display: inline-flex;
 justify-content: flex-end;
 margin-top: 10px;
 padding-top: 2px !important;
}
.settingstab-mobile{
  display: none;
}
.scrollTable
{
  width:1500px !important;
  overflow: auto !important;
}
.menuPadding
{
	padding-top: 4px !important;
}
.mobile-label
{
	width: 185px;
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    text-align: start ! important;
}
 .tableAlignCenter .mat-header-row {
    display: none !important;
  }

 .tableAlignCenter .mat-row { 
    flex-direction: column;
    align-items: start ! important;
    padding: 8px 24px;

  }
.tableAlignCenter .noLeftPadding{
  padding-left: 0px ! important;
}
 .tableAlignCenter{
      align-items: center;
}
 mat-row::after {
     min-height: auto !important;   
  }
.divScroll
{
  overflow:auto !important;
  min-width:600px !important;
}
.divtableScroll
{
  overflow:auto !important;
  width:1500px !important;
}
.autoTableScroll
{
  overflow:auto !important;
}
.autoContentScroll
{
  overflow:auto !important;
  width:1000px !important;
}
.fontSize13Px
{
  font-size: 13px !important;
}
.mobileTitlePadding{
  margin-top: 20px ! important;
}
.cardheadertitle{
  padding-top: 15px ! important;
}
.padding0Px{
  padding:0px ! important;

}

.paddingBottom{
  padding-bottom: 40px ! important;
}
.margin17px{
margin-top: 1% !important;
margin-bottom: 7% !important;

}
.example-full-width {
  width: 20% ! important; 
}
.leftPadding0Px{
  padding-left: 0px ! important;

}
.imageSiza
{
  max-width: 240px !important;
  max-height: 240px !important; 
  border: 1px solid !important;
  display: block !important;
  margin-right:auto !important;
  margin-left:auto !important;
}
.marginAuto
{
  padding-left:30% !important;
}
.mat-expansion-panel-header-description, .mat-expansion-panel-header-title
{
  margin-right: 5px !important;
}
mat-sidenav .mat-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    font-size: 15px !important;
    color: #3f51b5;
}
.leftexpansionpanel .mat-expansion-panel-header
{
  font-size: 1.2em !important;
}
mat-dialog-container#feedbackpopup {
    width: 100% !important;
     height: 380px !important;
}
mat-dialog-container#changepasspopup
{
  width:100% !important;
  height:380px !important;

}
.apidocument_resp
{
  width:100% !important;
}
table.available_api_ser
{
  width:100% !important;
}
.dec_vi_ui .mat-form-field
{
  width:100%;
}
.boxminheight {
    height: 100% !important;
    padding: 10px 0px;
}
.displaytable
{
  display: flex;
}
.tabledivresponsive {
    width: 100% !important;
    overflow: auto !important;
}