  .settingstab
{
 display: inline-flex;
 justify-content: flex-end;
 padding-top: 12px !important;
}
.settingstab-mobile{
  display: none;
}
.scrollTable
{
  width:1500px !important;
  overflow: auto !important;
}
.mobile-label
{
	display:none;
}
.paddingTop10px
{
  padding-top: 10px !important;
}
.mobileTitlePadding{
  margin-top: 20px ! important;
}
.cardheadertitle{
  padding-top: 15px ! important;
}
.paddingBottom{
	padding-bottom: 40px ! important;
}
.imageSiza
{
	max-width: 240px !important;
	max-height: 240px !important; 
	border: 1px solid !important;
	display: block !important;
}
