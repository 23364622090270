
/**
CSS FOR HIGH RESOLUTION >1500 px 
*/
/** settings menu for laptop **/
  .settingstab
{
  /* margin-top:12px; */
  padding: 10px 0px;
}
.settingstab-mobile{
  display:none;
}
.mat-table {
  overflow: auto;
  max-height: 668;
}

.mat-row{
 /* min-height: 62px !important;*/
  display: flex;
  line-height: 16px !important;
}
mat-sidenav a.mat-menu-item
{
padding-left: 10px;
    font-size: 15px;  
}

label.submenu
{
  padding: 5px !important;
  width: 100%;
  background-color: 
}

mat-header-cell{
  font-weight: bold !important;
}
.viewcontacts
{
  min-height: 420px;
}

.searchResults{
  min-height: 729px;
}
.mobile-label
{
  display:none;
}